import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import PersonLink from "../components/PersonLink"

const PublicationPage = ({ data }) => {
  const { publicationQuery, peopleQuery } = data
  const publication = publicationQuery["frontmatter"]
  const people = peopleQuery["edges"]

  const imageStyle = { borderRadius: "10px", maxWidth: 300 }

  return (
    <Layout>
      <div className="text-3xl my-6 font-bold"> {publication.name}</div>
      <div className="text-xl mb-3"> {publication.reference}</div>
      <div className="text-xl mb-2"> {publication.doi}</div>
      <div className="markdown">Abstract: {publication.description}</div> 
{/*       <div>People: </div>
      {people &&
        people.map((person, i) => <PersonLink key={i} person={person.node} />)} */}
    </Layout>
  )
}

export default PublicationPage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query PublicationByID($id: String!, $people: [String]) {
    publicationQuery: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        people
        reference
        description
        doi
      }
    }
    peopleQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "person-page" }
          name: { in: $people }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
